import ADD_SIGNATURE_TO_CONSULTATION from'@graphql/mutations/AddConsultationBlockToInformationClarification.graphql';import{useMutationWithValidationErrors}from'./useMutationWithValidationErrors';const useAddConsultationBlockToInformationClarification=()=>useMutationWithValidationErrors(ADD_SIGNATURE_TO_CONSULTATION,data=>{var _data$addConsultation;return(_data$addConsultation=data.addConsultationBlockToInformationClarification)===null||_data$addConsultation===void 0?void 0:_data$addConsultation.errors;},(cache,response,variables)=>{// cache.updateQuery(
//   {
//     query: GET_INFORMATI_BY_ID,
//     variables: {
//       consultationId: updatedConsultation?.id
//     }
//   },
//   (data: any) => {
//     return {
//       consultation: updatedConsultation
//     };
//   }
// );
});export default useAddConsultationBlockToInformationClarification;