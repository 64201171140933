const messages={// General
'navigate back':'Zurück','navigate forward':'Weiter','navigate overview':'Übersicht',print:'Drucken',archive:'Archiv',edit:'Bearbeiten',delete:'Löschen',select:'Auswählen',unselect:'Entfernen',close:'Schließen',undo:'Rückgängig machen',logout:'Abmelden','not found':'Nicht gefunden','deletion error':'Löschen fehlgeschlagen',// Error
'error title':'Etwas ist schief gelaufen','error description':'Bitte kontaktieren Sie den Support unter info@drclip.de',// Login
email:'E-Mail',password:'Passwort','login title':'Anmeldung','login button':'Anmelden','reset password button':'Passwort zurücksetzen',// Logged out
'logged out title':'Ausgeloggt','logged out text':'Sie haben sich erfolgreich abgemeldet',// Languages
'language en':'Englisch','language de':'Deutsch',// Loading
'loading initial data':'Lade Daten…',// Forms and form validation
signature:'Unterschrift','form save':'Speichern','form next':'Weiter','form save and close':'Speichern & schließen','form finish consultation':'Abschließen mit PDF','form finish consultation without pdf':'Abschließen ohne PDF','form cancel':'Abbrechen','form field fix error':'Bitte korrigieren Sie den Fehler','form field must not be empty':'Das Feld darf nicht leer sein','form checkbox must set':'Der Patient muss einwilligen','form send via email':'Senden','form field required':' *',// Footer pages
contact:'Kontakt','legal information':'Impressum',privacy:'Datenschutz',faq:'FAQ',// Pages in navigation
'page patients':'Patienten','page consultation':'Beratung','page library':'Mediathek','page archive':'Archiv','page information':'Information','page faq':'FAQ','page settings':'Einstellungen','page settings profile':'Profil','page settings theme':'Aussehen','page settings blocks':'Bausteine','page settings playlists':'Playlists','page next':'Ansehen',// Information
'add block':'Baustein','end info mode':'Patienteninfo beenden','search consultation block':'Baustein suchen','remove consultation block':'Baustein entfernen','add consultation blocks error':'Bitte fügen Sie Bausteine hinzu','consultation block destroy success':'Baustein entfernt','consultation block destroy error':'Der Baustein konnte nicht entfernt werden','consultation block toggle favorite success':'Baustein Favoritenstatus geändert','consultation block toggle favorite error':'Der Baustein konnte nicht als Favorit markiert werden','consultation block hide success':'Baustein aus-/ eingeblendet','consultation block hide error':'Baustein konnte nicht aus-/ eingeblendet werden','next consultation block':'Nächster Beratungsbaustein','previous consultation block':'Vorheriger Beratungsbaustein','information created':'Information erstellt','information created error':'Information konnte nicht erstellt werden','information sent':'Informationsbogen verschickt','information status':'Status','information status sent':'Versendet','information status pending':'Erstellt','information new':'Neue Information','prepare information':'Information vorbereiten',// User
Doctor:'Doktor','user deleted':'Nutzer gelöscht',// Header
'consultation tracked':'Aufklärung vollständig angesehen','consultation not tracked':'Aufklärung noch nicht vollständig angesehen','progress tracked':'Ihr Fortschritt wird erfasst',// Patient
patient:'Patient',patients:'Patienten',Patient:'Patient','create patient heading':'Patient anlegen','edit patient heading':'Patient bearbeiten','create patient':'Patient erstellen','search patient placeholder':'Bitte geben Sie den Namen eines Patienten ein.','patient details':'Patienten-Details','patient id':'Patientennummer','patient name':'Name','patient last name':'Name','patient first name':'Vorname','patient date of birth':'Geburtsdatum','patient gender':'Geschlecht','patient address':'Adresse','patient street address':'Straße und Hausnummer','patient zip':'PLZ','patient city':'Stadt','patient contact':'Kontaktdaten','patient email':'E-Mail','patient phone':'Telefon','patient misc info':'Sonstiges','patient insurance type':'Versicherungsart','patient receive email consent':'Erhalt von E-Mails','patient able to consent':'Einwilligungsfähig','patient image':'Patienten-Grafik','patient pdf':'Patienten-PDF','patient activation state':'Aktivierungsstatus','create patient success':'Patient angelegt','create patient error':'Fehler beim Anlegen des Patienten.','update patient success':'Patient gespeichert','update patient error':'Fehler beim Speichern des Patienten.','wrong birthday':'Das Geburtsdatum ist falsch!','create consultation success':'Beratung erstellt','create consultation error':'Beratung konnte nicht erstellt werden','archive patient':'Archivieren','archive patient success':'Patient archiviert','archive patient error':'Patient konnte nicht archiviert werden','reactivate patient':'Patient aktivieren','reactivate patient success':'Patient wurde reaktiviert','reactivate patient error':'Patient konnte nicht reaktiviert werden',pending:'ausstehend',active:'aktiviert',activate:'Neu senden',// Insurance
'insurance type private':'Privat','insurance type public':'Freiwillig','insurance type legal':'Gesetzlich','insurance unknown':'Unbekannt',// Gender
'gender male':'Männlich','gender female':'Weiblich','gender non-binary':'Divers','gender unknown':'Unbekannt',// Companions
'companion mother':'Mutter','companion father':'Vater','companion parents':'Eltern','companion spouse':'Lebenspartner/in','companion guardian':'Vormund','companion other':'Sonstiges',// SandBox
'sandbox consultation block overview':'Zu den Bausteinen','sandbox consultation overview':'Zur Beratungsübersicht','leave sandbox mode':'Logout',// Boolean
'boolean true':'Ja','boolean false':'Nein','boolean unknown':'Unbekannt',// Information Clarification
'information clarification':'Informationen','information clarification date':'Tag der Beratung',// Consultation
consultation:'Beratung',consultations:'Beratungen','consultation status':'Status','consultation status changed':'Status geändert','consultation sent?':'Versendet?','consultation mode prepare':'Vorbereitungsmodus','consultation mode present':'Aufklärungsmodus','prepare consultation':'Aufklärung vorbereiten','consultation data tracked':'Daten werden gespeichert','no consultation data tracked':'Es werden keine Daten getracked','consultation created':'Erstellt','consultation prepared':'Gesendet','consultation executed':'Durchgeführt','no consultations':'Keine Beratungen gefunden','no consultations yet':'Es stehen noch keine Beratungen zur Verfügung. Sie werden per E-Mail benachrichtigt, sobald das geschieht.','no information clarifications yet':'Es steht noch kein Informationsmaterial zur Verfügung. Sie werden per E-Mail benachrichtigt, sobald das geschieht.','no information clarifications':'Keine multimedialen Inhalte verfügbar','prepared consultations':'Vorbereitete Beratungen','consultation no data tracked':'Es werden keine Daten getrackt','consultation details':'Beratungsdetails','consultation contracts':'Aufklärungsbögen','consultation patient':'Patient','consultation date':'Tag der Beratung','consultation doctor':'Behandelnder Arzt','consultation employee':'Anwesender Mitarbeiter','consultation guardian':'Bevollmächtiger','consultation translator':'Dolmetscher','consultation companion type':'Begleitperson','consultation companion name':'Name der Begleitperson','consultation deleted':'Beratung gelöscht','edit consultation success':'Beratung gespeichert','edit consultation error':'Fehler beim Speichern der Beratung','download contract':'Aufklärungsbogen herunterladen','no archived contracts':'Keine Aufklärungsbögen','send prepared consultation to patient':'Senden','confirm destroy consultation':'Wollen Sie die Beratung wirklich löschen?','consultation new':'Neue Beratung',// Consultation blocks
'consultation block tracked':'Beratungsblock gespeichert','consultation block tracking error':'Fehler beim Speichern des Beratungsblocks','no further consultation Block available':'Es stehen keine weiteren Beratungsblöcke zur Verfügung','system blocks':'System Blöcke','user blocks':'Nutzer Blöcke',// Findings
finding:'Befund',Finding:'Befund',findings:'Befunde','add finding':'Befund hinzufügen','select finding':'Befund auswählen','search finding':'Befund suchen','back and select other finding':'Zurück und anderen Befund auswählen','no finding':'Kein Befund gefunden',// Therapies
therapy:'Therapie',Therapy:'Therapie',therapies:'Therapie','add therapy':'Therapie hinzufügen','select therapy':'Therapie auswählen','search therapy':'Therapie suchen','alternative therapy':'Alternative Therapie','back and select other therapy':'Zurück und andere Therapie auswählen',// Procedures
procedure:'Methode',Procedure:'Methode',procedures:'Methoden','add procedure':'Methode hinzufügen','select procedure':'Methode auswählen','search procedure':'Methode suchen','alternative procedure':'Alternative Methode','back and select other procedure':'Zurück und andere Methode auswählen',// Edit consultation blocks
'create block':'Baustein anlegen','edit block':'Baustein bearbeiten','search block':'Baustein suchen','block name':'Bausteinname','block type':'Bausteintyp','cover image':'Vorschaubild',video:'Video','filter blocks show all':'Filtern nach: Alle Fachbereiche','block updated at':'Zuletzt aktualisiert:','block fields':'Fachbereiche','block categories':'Kategorien','reset block to system block':'Auf Systembaustein zurücksetzen','add block success':'Baustein hinzugefügt','add block error':'Fehler beim Hinzufügen des Bausteins','add blocks success':'Bausteine hinzugefügt','add blocks error':'Fehler beim Hinzufügen der Bausteine','delete block success':'Baustein entfernt','delete block error':'Fehler beim Löschen des Bausteins','assign block success':'Bausteine verknüpft','assign block error':'Fehler beim Verknüpfen der Bausteine','block assignment':'Zuordnung',// Content blocks
'add content blocks':'Inhalte anlegen','add text content block':'Textblock hinzufügen','add media content block':'Foto hinzufügen','content block title':'Titel des Inhalts','content block saved':'Gespeichert',settings:'Einstellungen',contents:'Inhalte',// Playlists
playlist:'Playlist',playlists:'Playlists','playlist name':'Name','playlist save success':'Playlist gespeichert','playlist save error':'Fehler beim Speichern der Playlist','create new playlist':'Neue Playlist anlegen','edit playlist':'Playlist Namen editieren','search playlist':'Playlist suchen','user playlists':'Nutzer Playlisten','system playlists':'System Playlisten','copy playlist success':'Playlist kopiert','copy playlist error':'Playlist konnte nicht kopiert werden','assign consultation blocks to playlist':'Bausteine der Playlist hinzufügen','playlist delete success':'Playlist erfolgreich entfernt','playlist delete error':'Playlist entfernen fehlgeschlagen','playlist hide success':'Playlist aus-/ eingeblendet','playlist toggle favorite success':'Playlist Favoritenstatus geändert','playlist toggle favorite error':'Die Playlist konnte nicht als Favorit markiert werden','playlist hide error':'Playlist konnte nicht aus-/ eingeblendet werden',// Images / videos
'add image':'Grafik hinzufügen','media center':'Eigene Inhalte','attachment deleted':'Bild gelöscht','confirm destroy attachment':'Wollen Sie das Bild wirklich löschen?','media new':'Neue Grafik',// Office
office:'Praxis','office overview':'Archivübersicht','edit theme success':'Aussehen gespeichert','edit theme error':'Fehler beim Bearbeiten des Aussehens',archived:'Patient Archiviert',// Profile
'user profile':'Profil','user private data':'Persönliche Daten','user title':'Titel','user shorthand':'Kürzel','user last name':'Name','user first name':'Vorname','user credentials':'Login-Daten','user email':'E-Mail','user preferences':'Einstellungen','user language':'Sprache','user password change':'Passwort ändern','user new password':'Neues Passwort','user repeat new password':'Neues Passwort wiederholen','user forgot password':'Passwort vergessen?','user reset-password email: success':'Wir haben Ihnen eine Email geschickt mit den Anweisungen um Ihr Passwort zurückzusetzen','user reset-password email: error':'Diese Emailadresse existiert nicht',// Theme
'theme colors':'Farben','theme primary color':'Primärfarbe','theme secondary color':'Sekundärfarbe','theme highlight color':'Highlightfarbe','theme background color':'Hintergrundfarbe','theme logo':'Logo',// Search
'search terms':'Suchbegriffe','search terms comma separated':'Suchbegriffe, durch Kommas getrennt','results found':'{results, plural, =0 {Keine Ergebnisse} one {Ein Ergebnis} other {# Ergebnisse}} gefunden',// Filter
'filter by':'Filtern nach',// File upload
'file upload progress':'Datei wird hochgeladen:','file upload elapsed time':'Verstriche Zeit:','file upload seconds':'Sekunden','file upload already uploading':'Bitte warten Sie, bis alle bisherigen Uploads abgeschlossen sind!','file upload too large':'Dateigröße muss kleiner als 20 MB sein',// Comments
comment:'Kommentar','comment action':'Kommentieren','comment text':'Kommentartext','comment loading error':'Fehler beim Laden der Kommentare','comment with keyboard':'Texteingabe mit Tastatur','comment with drawing':'Schreiben und Zeichnen mit Finger oder Stift','create comment success':'Kommentar angelegt','create comment error':'Fehler beim Anlegen des Kommentars','update comment success':'Kommentar gespeichert','update comment error':'Fehler beim Bearbeiten des Kommentar','destroy comment confirm':'Wollen Sie den Kommentar wirklich löschen?','destroy comment success':'Kommentar gelöscht','destroy comment error':'Fehler beim Löschen des Kommentars',// Drawings on attachments
drawing:'Zeichnung','drawing save success':'Zeichnung gespeichert','drawing save error':'Fehler beim Speichern der Zeichnung','confirm clear drawing':'Wollen Sie die Zeichnung wirklich löschen?',// Mailer
'mail submission error':'Fehler beim Senden der Email','mail success':'Email wurde erfolgreich geschickt',// Token
'Invalid token':'Der Aktivierungslink ist ungültig.','Expired token':'Der Aktivierungslink ist abgelaufen',// 2FA
'user 2fa':'Zwei-Faktor-Authentifizierung','otp':'Zwei-Faktor-Code','via email':'E-Mail','via authenticator app':'Authenticator-App','2fa regenerate':'Neuen QR-Code generieren','2fa check authenticator':'Bestätigen','two factor strategy updated':'Zwei-Faktor-Authentifizierung aktualisiert','two factor strategy update error':'Fehler beim Aktualisieren der Zwei-Faktor-Authentifizierung','two factor disabled':'Zwei-Faktor-Authentifizierung deaktiviert','two factor enabled':'Zwei-Faktor-Authentifizierung aktiviert'};export default messages;