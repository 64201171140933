import React from 'react';
import { createPortal } from 'react-dom';

// We get hold of the div with the id modal that we have created in index.html
const modalRoot = document.getElementById('modal');

class Portal extends React.Component {
  constructor(props) {
    super(props);
    // We create an element div for this modal
    this.element = document.createElement('div');
  }

  // We append the created div to the div#modal
  componentDidMount() {
    modalRoot.appendChild(this.element);
  }

  componentWillUnmount() {
    modalRoot.removeChild(this.element);
  }

  render() {
    // eslint-disable-next-line react/destructuring-assignment
    return createPortal(this.props.children, this.element);
  }
}
export default Portal;
