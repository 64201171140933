import CREATE_CONSULTATION from'@graphql/CreateConsultation.graphql';// import GET_CONSULTATION_BY_ID from '@graphql/queries/GetConsultationById.graphql';
import{useMutationWithValidationErrors}from'./useMutationWithValidationErrors';const useCreateConsultation=()=>useMutationWithValidationErrors(CREATE_CONSULTATION,data=>{var _data$createConsultat;return(_data$createConsultat=data.createConsultation)===null||_data$createConsultat===void 0?void 0:_data$createConsultat.errors;}// (cache, response) => {
//   const updatedConsultation = response.data?.createConsultation?.consultation;
//   cache.writeQuery({
//     query: GET_CONSULTATION_BY_ID,
//     data: {
//       consultation: updatedConsultation
//     }
//   });
// }
);export default useCreateConsultation;