import _styled from"styled-components";import UnstyledButton from'@ui-components/Button';import UnstyledHeading from'@ui-components/Heading';import UnstyledSearchInput from'@ui-components/forms/SearchInput';export const Heading=_styled(UnstyledHeading).withConfig({displayName:"styles__Heading",componentId:"sc-11np3pa-0"})(["margin:3rem 0 1rem 0;"]);export const SearchInput=_styled(UnstyledSearchInput).withConfig({displayName:"styles__SearchInput",componentId:"sc-11np3pa-1"})(["margin-bottom:2em;"]);export const Button=_styled(UnstyledButton).withConfig({displayName:"styles__Button",componentId:"sc-11np3pa-2"})([""]);export const Header=_styled.div.withConfig({displayName:"styles__Header",componentId:"sc-11np3pa-3"})(["justify-content:space-between;display:flex;margin-bottom:3em;"]);// export const H4 = styled.h4`
//   margin-bottom: 0.5em;
//   font-size: ${({ theme }) => theme.fonts.fontSizes.small};
//   font-weight: 400;
//   text-transform: uppercase;
//   color: ${({ theme }) => theme.colors.primary};
// `;
// export const PlaylistSection = styled.section`
//   margin-bottom: 2em;
// `;
// export const PlaylistName = styled(UnstyledHeading)`
//   justify-content: space-between;
//   align-items: center;
//   display: flex;
//   margin-top: 0;
//   border-radius: 1em;
//   padding-left: 0.8rem;
//   background-color: ${({ theme }) => theme.colors.background};
//   font-size: ${({ theme }) => theme.fonts.fontSizes.medium};
//   text-transform: capitalize;
// `;
// export const FindingContainer = styled.div`
//   margin-left: 1em;
// `;
// export const TherapyContainer = styled.div`
//   grid-template-columns: 1fr 1fr;
//   gap: 1em;
//   display: grid;
//   margin-top: 1em;
// `;
// export const TherapyCard = styled.div`
//   border: 1px solid #dbdbde;
//   border-radius: 0.8em;
//   min-width: 18em;
//   padding: 1em;
//   & > *:nth-child(-n + 3) {
//     margin-bottom: 0.8em;
//   }
// `;
// export const FavoritesButton = styled.button`
//   outline: none;
//   border: none;
//   padding: 0;
//   background: transparent;
//   text-align: left;
//   text-decoration: none;
// `;