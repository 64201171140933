import{useMutation}from'@apollo/client';import{transformRailsToRHFErrors}from'@utils/errorTransform';/**
 * Error that is thrown when the server returns an errors object.
 */export class ValidationError extends Error{constructor(errors){super('Server-side validation error');this.errors=errors;}}/**
 * Wraps a useMutation hook and provides the server errors object.
 * Pass the data type and the variables type as type parameters.
 *
 * @param mutation The GraphQL mutation
 * @param getErrors Accessor function that returns the errors
 * @param update Function for updating the cache. This function receives the cache, the result and the variables.
 * @param options Mutation hook options (optional)
 * @example
 * const useUpdatePlaylist = () =>
 *   useMutationWithValidationErrors<UpdatePlaylist, UpdatePlaylistVariables>(
 *     UPDATE_PLAYLIST,
 *     (data) => data.updatePlaylist?.errors,
 *     (cache, result, variables) => {
 *       // Update cache
 *     },
 *     // Other options (optional)
 *     { … }
 *   );
 * // In the component:
 * const { mutate, validationErrors } = useUpdatePlaylist();
 * const onSubmit = async () => {
 *   try {
 *     const result = await mutate(variables);
 *     // Do something with result
 *     toast.success(intl.formatMessage({ id: 'success' }));
 *   } catch (error) {
 *     toast.error(intl.formatMessage({ id: 'error' }));
 *   }
 * };
 */export function useMutationWithValidationErrors(mutation,getErrors,update,options,progressOptions){const[mutate,result]=useMutation(mutation,options);let errors;let abort=()=>{};const{data}=result;if(data){const rawErrors=getErrors(data);if(rawErrors){errors=transformRailsToRHFErrors(rawErrors);}}return{mutate:async variables=>{// Wrap the update function so we can pass the variables
// since they are not available in the function’s scope.
const wrappedUpdate=update&&((cache,mutationResult)=>// Pass variables to the update function
update(cache,mutationResult,variables));let response;if(progressOptions){console.log('TRACK PROGRESS');console.log(progressOptions);response=await mutate({variables,update:wrappedUpdate,context:{fetchOptions:{useUpload:true,onProgress:event=>{if(progressOptions){if(progressOptions.onProgress){progressOptions.onProgress(event);}if(event.loaded/event.total===1){if(progressOptions.onComplete){progressOptions.onComplete();}}}},onAbortPossible:abortHandler=>{abort=abortHandler;}}}});}else{response=await mutate({variables,update:wrappedUpdate});}if(!response.data){throw new Error('no data returned');}const rawResponseErrors=getErrors(response.data);if(rawResponseErrors){throw new ValidationError(rawResponseErrors);}return response.data;},data:result.data||undefined,loading:result.loading,error:result.error,validationErrors:errors,abort};}