import TOGGLE_FAVORITE_CONSULTATION_BLOCK from'@graphql/mutations/ToggleFavoriteConsultationBlock.graphql';import{useMutationWithValidationErrors}from'./useMutationWithValidationErrors';const useToggleFavoriteConsultationBlock=()=>useMutationWithValidationErrors(TOGGLE_FAVORITE_CONSULTATION_BLOCK,data=>{var _data$toggleFavoriteC;return(_data$toggleFavoriteC=data.toggleFavoriteConsultationBlock)===null||_data$toggleFavoriteC===void 0?void 0:_data$toggleFavoriteC.errors;},(cache,response)=>{// const updatedConsultationBlock =
//   response.data?.swapContentBlockSortIds?.consultationBlock;
// if (updatedConsultationBlock?.id) {
//   cache.writeQuery({
//     query: GET_CONSULTATION_BLOCK,
//     variables: {
//       id: updatedConsultationBlock.id
//     },
//     data: {
//       consultationBlock: updatedConsultationBlock
//     }
//   });
// }
});export default useToggleFavoriteConsultationBlock;